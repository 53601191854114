import "./style.scss";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import domReady from "@wordpress/dom-ready";

function slider() {
  // Select all elements with the class 'wp-block-intempus-logos slideshow'
  const slideshows = document.querySelectorAll(
    ".wp-block-intempus-logos.slideshow"
  );

  // Iterate over each slideshow element
  slideshows.forEach((logos) => {
    const slides = logos.getAttribute("data-slides");
    const autoPlay = logos.getAttribute("data-autosplide");
    const autoSmooth = logos.getAttribute("data-autosmooth");
    const autoSpeed = logos.getAttribute("data-autospeed");

    const speed = autoSpeed ? autoSpeed : 7500;

    var glide = new Glide(logos, {
      type: "carousel",
      perView: slides,
      autoplay: autoPlay || autoSmooth ? 1 : false,
      animationDuration: slides == 1 ? 1000 : autoSmooth ? speed : 5000,
      animationTimingFunc: autoSmooth ? "linear" : "",
      breakpoints: {
        777: {
          perView: slides > 2 ? slides - 1 : slides,
        },
      },
    });

    glide.mount();
  });
}

domReady(slider);
